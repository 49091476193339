.SmsSentHistoryOption {
  .sms-sent-list-section {
    .sms-sent-list-btns {
      text-align: right;
      .sms-sent-list-btn {
        display: inline-block;
        margin: 0.2rem;

        .sms-search-btn {
          background: #006ec3;
          color: #fff;
        }

        .sms-sent-list-clear-btn {
          background: #eee;
        }

        button {
          width: auto;
          padding: 0.6rem;
          border-radius: 6px;
        }
      }
    }
  }
}
