.UserCircle {
  padding-right: 1.2rem;
  img {
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    -o-object-fit: cover;
    object-fit: cover;
    -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.016), 0 3px 6px rgba(0, 0, 0, 0.023);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.016), 0 3px 6px rgba(0, 0, 0, 0.023);
    -webkit-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    cursor: pointer;
  }
}
