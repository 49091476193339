.modal {
  .ui.placeholder {
    max-width: 100%;
  }
  .map-detail-desc {
    margin: 1rem 0;
  }
  .map-date-wrap {
    display: flex;

    .map-detail-producer {
      font-size: 0.9rem;
      margin-right: 0.2rem;
    }
    .map-detail-date {
      font-size: 0.9rem;
      margin-left: 0.2rem;
    }
  }

  img {
    max-width: 100%;
  }
}
