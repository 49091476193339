.EditorPreview {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  font-size: 1rem;
  word-break: break-word;
  word-wrap: break-word;
  scroll-behavior: smooth;

  .preview-title {
    font-size: 2.5rem;
    font-weight: 600;
    padding-bottom: 1rem;
    line-height: 1.5em;
    margin-bottom: 2rem;
    border-bottom: 1px solid #bdbdbd;
    font-family: 'Noto Serif KR', serif;
  }
}
