.SmsRecipientList {
  margin: 1rem 0;
  .SmsRecipientItem {
    display: inline-flex;
    font-size: 1rem;
    background: #007edf;
    padding: 0.5rem;
    border-radius: 6px;
    color: #fff;
    margin: 0.3rem;
    .recipient-number {
      margin-right: 0.2rem;
    }

    .recipient-remove {
      cursor: pointer;

      &:hover {
        color: #e74448;
      }
    }
  }
}
