.SettingHeader {
  display: flex;
  border-bottom: 1px solid #eee;
  padding-bottom: 1rem;

  .thumbnail {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
    width: 12rem;
    height: 12rem;
    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 6rem;
      object-fit: cover;
      margin-bottom: 1rem;
    }

    .change-thumbnail-btn {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 6rem;
      object-fit: cover;
      margin-bottom: 1rem;
      position: absolute;
      top: 0;
      text-align: center;
      background: rgba(51, 51, 51, 0.6);
      color: #fff;
      visibility: hidden;
      font-weight: 500;
      font-size: 1.25rem;
    }

    &:hover {
      .change-thumbnail-btn {
        visibility: visible;
      }
    }
  }

  .user-info-wrap {
    position: relative;
    .user-info {
      position: absolute;
      top: 25%;
      min-width: 24rem;

      .user-nickname-wrap {
        .user-nickname {
          display: inline-block;
        }
        svg {
          margin-left: 0.3rem;
          display: inline-block;
          font-size: 1.45rem;
          vertical-align: baseline;
          cursor: pointer;
        }

        input {
          padding: 0.4rem;
          font-size: inherit;
          font-family: inherit;
          line-height: normal;
          background-color: #f5f5f5;
          border: 1px solid #ebebeb;
          border-bottom-color: #e2e2e2;
          border-radius: 0.25em;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin-right: 0.2rem;
        }

        button {
          color: #333;
          background: #fff;
          padding: 0.4rem;
          border-radius: 6px;
          border: 1px solid #ebebeb;
          font-weight: 500;
          margin: 0 0.1rem;
          cursor: pointer;
        }
      }
    }
  }
}
