.SidebarMenu {
  margin: 1rem 0;
  .ui.accordion {
    .active {
      color: #4b96e6;
    }
    .title:not(.ui) {
      font-size: 1rem;
      font-family: inherit;
      padding: 0 1rem;
      line-height: 3rem;
      -ms-user-select: none;
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;

      &:hover {
        color: #4b96e6;
        background-color: #e3f0ff;
      }
      i.icon {
        font-size: 0.7rem;
        float: right;
      }
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: -0.5rem 0;
    a {
      li {
        display: inline-block;
        position: relative;
        z-index: 100;
        width: 100%;
        color: #222;
        padding: 11px 50px 10px 30px;
        font-size: 1rem;

        &:hover {
          color: #4b96e6;
          background-color: #e3f0ff;
        }
      }
    }
  }
}
