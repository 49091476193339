.SettingTemplate {
  main {
    margin: 2rem auto 0;
  }
}

@media all and (min-width: 769px) {
  .SettingTemplate {
    main {
      width: 768px;
      margin: 4rem auto 0;
    }
  }
}
