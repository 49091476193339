.Header {
  .header-wrap {
    background: #006ec3;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;

    .logo {
      font-size: 1.5rem;
      padding: 1rem 0 1rem 1.5rem;

      a {
        color: #fff;
      }
    }

    .header-right {
      margin-left: auto;
    }
  }
}
