.EditorUploadMask {
  border: 4px solid #006ec3;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  svg {
    font-size: 9rem;
  }

  h3 {
    margin: 0;
    font-size: 3rem;
    font-weight: 300;
  }
}
