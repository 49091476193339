.Popup {
  display: block;
  padding-right: 17px;
  overflow-x: hidden;
  overflow-y: auto;
  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  // display: none;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  // opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;

  .popup-backdrop {
    height: 100%;
    opacity: 0.5;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: #000;
  }

  .popup-dialog {
    width: auto;
    position: relative;
    margin: 4rem auto;

    .popup-content {
      padding: 20px 28px;
      border: 2px solid #333;
      border-radius: 4px;
      -webkit-box-shadow: 3px 4px 2px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 3px 4px 2px rgba(0, 0, 0, 0.1);
      box-shadow: 3px 4px 2px rgba(0, 0, 0, 0.1);
      position: relative;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      background-clip: padding-box;
      border: 1px solid #999;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 6px;
      outline: 0;
      -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);

      .popup-header {
        border-bottom: 1px solid #ccc;
        min-height: 1rem;
        padding: 1rem;

        .close-btn {
          -webkit-appearance: none;
          padding: 0;
          cursor: pointer;
          background: 0 0;
          border: 0;
          float: right;
          font-size: 21px;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #fff;
          filter: alpha(opacity=20);
          opacity: 0.2;
          font-size: 2rem;
        }

        .popup-title {
          font-size: 1.5rem;
          margin: 0;
          line-height: 1.42857143;
        }
      }
      .popup-body {
        padding: 1rem 0;
        font-size: 0.9rem;
      }

      .popup-footer {
        padding-top: 28px;
        text-align: center;
        padding: 15px;
        text-align: right;
        border-top: 1px solid #e5e5e5;

        button {
          background-color: #eee;
          padding: 1rem;
          border-radius: 0.4rem;
          cursor: pointer;
          margin: 0 0.3rem;
        }

        .popup-close-btn {
          color: #eee;
          background-color: #006ec3;
        }
      }
    }
  }
}

@media all and (min-width: 768px) {
  .Popup {
    .popup-dialog {
      width: 600px;
    }
  }
}

@media all and (min-width: 992px) {
  .Popup {
    .popup-dialog {
      width: 900px;
    }
  }
}
