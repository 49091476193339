.MemberAddOptions {
  .member-csv-wrap {
    display: flex;

    .csv-template-download {
      margin-right: 0.5rem;
      display: flex;
      a {
        color: #333;
        background: #fff;
        padding: 0.4rem;
        border-radius: 6px;
        border: 1px solid #ebebeb;
        font-weight: 500;

        &:hover {
          background: #ebebeb;
        }
      }
    }
    .member-csv-upload {
      .member-csv-file-name {
        padding: 0.4rem; /* label의 패딩값과 일치 */
        font-size: inherit;
        font-family: inherit;
        line-height: normal;
        background-color: #f5f5f5;
        border: 1px solid #ebebeb;
        border-bottom-color: #e2e2e2;
        border-radius: 0.25em;
        -webkit-appearance: none; /* 네이티브 외형 감추기 */
        -moz-appearance: none;
        appearance: none;
        margin-right: 0.2rem;
      }

      label {
        color: #333;
        background: #fff;
        padding: 0.4rem;
        border-radius: 6px;
        border: 1px solid #ebebeb;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          background: #ebebeb;
        }
      }

      input[type='file'] {
        /* 파일 필드 숨기기 */
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }
    }
  }
}
