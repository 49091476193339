.SmsDelivery {
  .sms-delivery-section {
    margin: 0.6rem 0;

    .sms-delivery-btns {
      text-align: right;

      .sms-delivery-btn {
        display: inline-block;
        margin: 0.2rem;
        button {
          width: auto;
          padding: 0.6rem;
          border-radius: 6px;
        }

        .sms-send-btn {
          background: #006ec3;
          color: #fff;
        }

        .sms-clear-btn {
          background: #eee;
        }
      }
    }
  }
}
