.LicenseCodeDetailPopup {
  h2 {
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
  }
  .license-code-detail-info-wrap {
    .license-code-detail-info {
      .license-code-detail-section {
        display: flex;
        flex-wrap: wrap;
        margin: 1rem 0;
        padding: 0 1rem;

        .license-code-used-title {
          display: block;
          width: 100%;
          margin-bottom: 0.5rem;
        }

        .license-code-detail-box {
          flex: 1 1;

          label {
            min-width: 6rem;
            font-weight: 500;
          }

          p {
            min-width: 13rem;
          }

          .detail-receiver-list {
            .detail-receiver-item {
              margin-right: 0.6rem;
              display: inline-flex;
              background: #007edf;
              border-radius: 4px;
              padding: 0 0.3rem;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
