.PromotionDetailPopup {
  .promotion-info-wrap {
    padding: 1rem;
    .promotion-info-section {
      display: flex;
      margin-bottom: 0.5rem;

      .promotion-info-box {
        min-width: 15rem;

        .promotion-info-header {
          font-size: 1rem;
          font-weight: 500;
        }
      }
    }
  }
}
