.MapList {
  display: flex;
  flex-wrap: wrap;
  .MapItem {
    position: relative;
    width: calc(25% - 1.75rem);
    background: #fff;
    margin: 1.5rem 0.875rem;
    box-shadow: 0 1px 2px #ccc;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 2px 8px #bbb;
    }
    .map-item-wrap {
      .map-thumbnail {
        background-image: url(https://api-storage.cloud.toast.com/v1/AUTH_a7b545f……20-02-06-16-33-30/5cde3a5…_2020-02-06-16-33-30_288x162.png);
        height: 16rem;
        background-size: cover;
        background-position: center;
      }
      .map-info-wrap {
        padding: 1rem 0.875rem;

        .map-desc {
          margin: 0.8rem 0;
        }
        .map-producer {
          font-size: 0.8rem;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .MapList {
    .MapItem {
      .map-item-wrap {
        .map-thumbnail {
          height: 0;
          padding-top: 100%;
        }
      }
    }
  }
}
