.PaymentDetailPopup {
  .payment-info-wrap {
    padding: 0 1rem;

    .payment-info-section {
      display: flex;
      margin: 0.6rem 0;
      .payment-info-box {
        min-width: 50%;
        .payment-info-header {
          font-size: 1.2rem;
          font-weight: 500;
        }

        .payment-info-body {
          button {
            background: #006ec3;
            color: #fff;
            padding: 0.5rem;
            border-radius: 4px;
            min-width: 3rem;
            text-align: center;
          }
          .payment-info-body-section {
            display: flex;

            .payment-info-body-box {
              width: 50%;
              label {
                font-weight: 500;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
