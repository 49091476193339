.LicenseCodeList {
  .license-code-detail-btn {
    padding-bottom: 0.125rem;
    color: #007edf;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid #007edf;
    }
  }
}
