.EditorPanes {
    flex: 1;

    .panes {
        height: calc(100vh - 4rem);
        display: flex;
        position: relative;

        .pane {
            display: flex;
            min-width: 0;
            overflow: auto;
        }

        @media all and (max-width: 768px) {
            .editor {
                flex: 1 !important;
            }

            .preview,
            .divide {
                display: none;
            }
        }

        .divide {
            width: 10px;
            height: 100%;
            position: absolute;
            transform: translate(-50%);
            cursor: col-resize;
            background: #fff;
        }

    }
}