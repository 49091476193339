.DashBoardBody {
  padding: 1rem;
  background-color: #f5f5f5;

  .DashBoardOption {
    padding: 1rem;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: #bbb;
  }

  .DashBoardContent {
    padding: 1rem;
    background: #fff;
    border: 1px solid #eee;
    border-radius: 4px;
    margin: 0.5rem 0;
  }
}
