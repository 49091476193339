.LoginTemplate {
  height: 100%;

  .login-bg {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }
}
