.EmailPreview {
  .email-preview-info-wrap {
    padding: 0 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #eee;

    .email-preview-section {
      display: flex;
      margin: 0.5rem 0;

      label {
        min-width: 6rem;
      }

      .preview-receiver-list {
        .preview-receiver-item {
          margin-right: 0.6rem;
          display: inline-flex;
          background: #007edf;
          border-radius: 4px;
          padding: 0 0.3rem;
          color: #fff;
        }
      }

      .preview-carbon-copy-list {
        .preview-carbon-copy-item {
          margin-right: 0.6rem;
          display: inline-flex;
          background: #ccc;
          border-radius: 4px;
          padding: 0 0.3rem;
          color: #fff;
        }
      }
    }
  }
  .email-preview-body {
    padding: 1rem;
  }
}
