.SubmitBox {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  background: #ffffff;
  width: 24rem;
  z-index: 10;
  color: #000000;

  .option-wrap {
    padding: 2rem;
    border: 1px solid #cccccc;

    .option-section {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #cccccc;

      &:first-child {
        padding-top: 0;
      }

      .option-title {
        margin-bottom: 0.5rem;
      }
    }
  }

  .post-submit {
    text-align: center;
    margin-top: 1rem;

    button {
      width: 100%;
      padding: 0.6rem;
      font-size: 1.3rem;
      background: #454552;
      color: #ffffff;
      cursor: pointer;
      border-radius: 10px;
      letter-spacing: 12px;
      transition: all 0.125s ease-in;

      &:hover {
        background: #006ec3;
      }
    }
  }
}
