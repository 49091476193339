.markdown-render {
  word-break: break-word;
  word-wrap: break-word;
  line-height: 30px;

  p {
    color: #333;
    font-size: 1.1rem;
    font-weight: 400;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    code {
      background-color: #f8f8f8;
      color: #2196f3;
      padding: 3px 5px;
      margin: 0 2px;
      border-radius: 2px;
      white-space: nowrap;
    }

    a {
      color: #2196f3;

      &:hover {
        border-bottom: 1px solid #2196f3;
      }
    }
  }

  pre {
    background: #272822;
    color: #f8f8f2;
    padding: 1em;
    margin: 2em 0;
    overflow: auto;
    border: 1px solid #f1f3f5;
    // background: #fafafa;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #212529;
    font-family: Noto Serif KR, sans-serif;
    font-weight: 700;
    margin-top: 2em;
    margin-bottom: 1em;
  }

  blockquote {
    border-left: 4px solid #ddd;
    padding: 0 15px;
    color: #777;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
  }

  img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 3em;
    margin-bottom: 3em;
    pointer-events: none;
  }

  hr {
    border-top: 1px solid #dee2e6;
    margin: 15px 0;
    padding: 0;
  }
}
