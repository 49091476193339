.PromotionListPagination {
  display: block;
  margin-top: 3rem;
  text-align: center;

  ul {
    display: table;
    margin-left: auto;
    margin-right: auto;
  }

  .rc-pagination-item:hover a {
    color: #ffffff;
  }

  li {
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    width: 30px;
    line-height: 30px;
    margin-right: 5px;
    margin-left: 5px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    outline: none;

    a {
      outline: none;
      display: block;
    }

    &:hover {
      transition: all 0.2s linear;
      background: #006ec3;
    }
  }

  .rc-pagination-jump-prev:after,
  .rc-pagination-jump-next:after {
    content: '\2022\2022\2022';
    display: block;
    letter-spacing: 2px;
    color: #555555;
    font-size: 16px;
    margin-top: 0px;
  }

  .rc-pagination-jump-prev:after,
  .rc-pagination-jump-next:after {
    content: '\2022\2022\2022';
    display: block;
    letter-spacing: 2px;
    color: #555555;
    font-size: 16px;
    margin-top: 0px;
  }

  .rc-pagination-jump-next:hover:after {
    color: #ffffff;
    font-size: 16px;
    content: '\BB';
  }

  .rc-pagination-jump-prev:hover:after {
    color: #ffffff;
    font-size: 16px;
    content: '\AB';
  }

  .rc-pagination-item-active {
    transition: all 0.2s linear;
    background: #006ec3;
    border-radius: 20px;
    color: #ffffff;
  }
}
