.LeftDashBoard {
  min-height: 100vh;
  max-height: 100%;
  width: 230px;
  padding: 0;
  position: absolute;
  overflow: hidden;
  z-index: 1;
  background: #ededed;
  border-right: 1px solid #d9dee4;
  background: #fff;
}
