.MemberDetailPopup {
  background: #ffffff;
  width: 600px;

  .member-detail-top {
    display: flex;
    padding: 1rem;

    span {
      font-size: 1.25rem;
    }

    .member-detail-close {
      margin-left: auto;
      cursor: pointer;

      svg {
        font-size: 2rem;
      }
    }
  }

  .member-detail-info {
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;

    .member-email {
      margin-bottom: 0.5rem;
    }

    .member-nickname {
      margin-bottom: 0.5rem;
    }

    .member-type {
      margin-bottom: 0.5rem;
    }

    .member-join-date {
      margin-bottom: 0.5rem;
    }

    .member-login-date {
      margin-bottom: 0.5rem;
    }

    .license-grade {
      margin-bottom: 0.5rem;
    }

    .license-expire {
      margin-bottom: 0.5rem;
    }

    .update-btns {
      display: flex;
      text-align: center;
      font-size: 1.125rem;
      font-weight: 500;

      div {
        width: 100%;
        cursor: pointer;
        background: #bbbbbb;
        border-radius: 1rem;
        padding: 0.5rem;
        margin: 0.2rem;
      }
    }
  }
}
