.LoginForm {
  .login-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;

    .login-title {
      font-weight: 400;
      font-size: 1.8rem;
      text-align: center;
      color: #000;
    }

    .login-input-wrap {
      .input-email {
        margin-top: 0.5rem;
      }
      .input-pwd {
        margin-top: 0.5rem;
      }
    }

    .login-btn {
      margin-top: 0.5rem;
    }
  }
}

@media all and (max-width: 500px) {
  .LoginForm {
    .login-wrap {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}
