.MemberList {
  table {
    width: 100%;
  }
}

.loading {
  padding-top: 5rem;
  width: 360px;
  margin: 0 auto;
  font-size: 2rem;
}
