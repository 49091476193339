.LabelWithNode {
  label {
    font-weight: 500;
  }

  .node-wrap {
    margin: 0.2rem 0;

    .input {
      width: 100%;
    }

    select[id='sms-message-type'] {
      padding: 0.3rem;
      outline: none;

      option {
        padding: 2rem;
      }
    }

    .react-datetime-picker {
      .react-datetime-picker__wrapper {
        border: 1px solid hsl(0, 0%, 80%);
        border-radius: 4px;
        padding: 0.2rem;
      }
    }

    input[type='text'] {
      width: 100%;
      border: 1px solid hsl(0, 0%, 80%);
      border-radius: 4px;
      outline: none;
      padding: 0.6rem;
      padding-left: 0.5rem;
    }

    textarea {
      width: 100%;
      resize: none !important;
      border: 1px solid hsl(0, 0%, 80%);
      outline: none;
      border-radius: 4px;
      padding: 0.6rem;
    }
  }

  .notify-wrap {
    font-size: 0.8rem;

    .notify-left {
      color: #e74448;
    }

    .notify-right {
      float: right;
      color: #333;
      font-weight: 500;
    }
  }
}
