.LicenseCodeUsedUsers {
  position: relative;
  .license-code-used-email {
    display: inline-block;
    background: #eee;
    padding: 0.3rem;
    border-radius: 4px;
    margin: 0 0.5rem 0.5rem 0.5rem;
    cursor: pointer;
  }
  .license-code-used-date {
    display: none;
    position: absolute;
    top: 2.5rem;
    font-size: 0.7rem;
    background: #006ec3;
    padding: 0.5rem;
    border-radius: 4px;
    color: #eee;

    z-index: 2;

    &::before {
      content: '';
      position: absolute;
      top: -14px;
      width: 0px;
      height: 0px;
      border-bottom: 50px solid #006ec3;
      border-right: 50px solid transparent;
      border-left: 50px solid transparent;
      z-index: -1;
    }
  }

  &:hover {
    .license-code-used-date {
      display: block;
    }
  }
}
