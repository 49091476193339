.PromotionRegister {
  .promotion-register-section {
    margin: 0.6rem 0;

    .promotion-register-btns {
      text-align: right;

      .promotion-register-btn {
        display: inline-block;
        margin: 0.2rem;
        button {
          width: auto;
          padding: 0.6rem;
          border-radius: 6px;
        }

        .promotion-add-btn {
          background: #006ec3;
          color: #fff;
        }

        .promotion-clear-btn {
          background: #eee;
        }
      }
    }
  }
}
