.SmsSentHistoryItem {
  td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    text-align: center;

    p {
      color: #007edf;
      cursor: pointer;

      &:hover {
        color: #006ec3;
      }
    }
  }
}
