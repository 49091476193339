.SmsSentHistoryDetailPopup {
  h2 {
    padding: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #eee;
  }

  .sms-detail-info-wrap {
    .sms-detail-info {
      .sms-detail-section {
        display: flex;
        margin: 1rem 0;

        label {
          min-width: 6rem;
          padding: 0 1rem;
          font-weight: 500;
        }

        p {
          min-width: 16rem;
          padding: 0 0.5rem;
        }

        .detail-receiver-list {
          .detail-receiver-item {
            margin-right: 0.6rem;
            display: inline-flex;
            background: #007edf;
            border-radius: 4px;
            padding: 0 0.3rem;
            color: #fff;
          }
        }
      }
    }
  }

  .sms-info-wrap {
    .sms-info {
      padding: 1rem;

      .sms-title {
        font-size: 1rem;

        label {
          font-size: 1rem;
          margin-bottom: 0.4rem;
          font-weight: 500;
        }

        .sms-title-content {
          margin: 0.4rem 0;
          font-size: 1.25rem;
          font-weight: 500;
        }
      }
      .sms-body {
        label {
          font-size: 1rem;
          margin-bottom: 0.4rem;
          font-weight: 500;
        }

        .sms-body-content {
          padding: 1rem;
          background: #eee;
          margin: 0.5rem 0;
        }
      }
    }
  }
}
