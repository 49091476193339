.EmailReceiverList {
  margin: 0.3rem;
  .EmailReceiverItem {
    display: inline-flex;
    position: relative;
    background: #eee;
    border-radius: 4px;
    padding: 0.3rem;
    font-size: 0.9rem;
    margin: 0.3rem;

    .receiver-remove {
      margin-left: 0.4rem;
      cursor: pointer;

      &:hover {
        color: red;
      }
    }
  }
}
