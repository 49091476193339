.NoticeItem {

    td {
        border: 1px solid #ddd;
        text-align: left;
        padding: 8px;
        text-align: center;

        button {
            cursor: pointer;
        }
    }
}