.PaymentListOptions {
  .payment-list-option-section {
    .payment-list-download-btn {
      padding: 0.6rem;
      background: #006ec3;
      color: #fff;
      border-radius: 6px;

      svg {
        font-size: 1.5rem;
        margin-right: 0.3rem;
      }
    }
    .payment-list-btns {
      text-align: right;
    }
  }
}
