.AdminListOptions{
  .admin-list-options-section{
    margin: 0.5rem 0;
    .admin-list-options-btns{
      text-align:right;
    }
  }

  .date-group{
    display: flex;
  }
}