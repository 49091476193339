.DashBoardHeader {
  padding: 1.5rem 2rem;

  .dashboard-title {
    margin-bottom: 1rem;
  }

  .dashboard-tab-wrap {
    clear: both;
    position: relative;
    width: 100%;
    padding-top: 2px;
    white-space: nowrap;
    border-bottom: 1px solid #eee;

    .tab-list {
      position: relative;
      list-style: none;
      padding-left: 0.5rem;
      margin: 0;
      display: inline-flex;

      .tab-item {
        margin-left: 1.5rem;
        padding: 0.5rem 0 0.5rem;

        span {
          position: relative;
          z-index: 1;
          border: 0;
          border-bottom: 0;
          font-size: 1rem;
          color: #222;
          cursor: pointer;
        }

        &:hover {
          border-bottom: 2px solid #ccc;
        }
      }

      .tab-selected {
        border-bottom: 2px solid #006ec3 !important;
      }
    }
  }
}
