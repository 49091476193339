.LicenseUpdatePopup {
    background: #ffffff;
    width: 600px;

    .license-update-top {
        display: flex;
        padding: 1rem;

        span {
            font-size: 1.25rem;
        }

        .license-update-close {
            margin-left: auto;
            cursor: pointer;

            svg {
                font-size: 2rem;
            }
        }
    }

    .license-update-info {
        text-align: left;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;

        .update-section {
            margin-bottom: .5rem;

            .update-title {
                font-size: 1.125rem;
                font-weight: 500;
            }
        }
    }
}